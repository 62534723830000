import React from 'react'
import Layout from '../../components/layout'
import Helmet from 'react-helmet'
import {
  Section,
  Row,
  Col,
  Container,
  Responsive,
} from '../../components/containers/Layout'
import { PostPreview, PostPreview2 } from '../../components/blog/PostPreview'


const Page = () => (
  <Layout>
    <Section style={{
      backgroundColor: 'white'
    }}>
      <Container style={{maxWidth: 620}}>
        <Col>

          Proof

          - Harvard
          - Stanford
          - NYU
          - Viome
          
        </Col>

        <Col>

          Benefits

          - easier to log
            - faster to input
            - more engaging
            - instant feedback
          - better data
            - image (full picture, compare image vs log entry)
            - location
          - improved adherence
            - notifications
            - engagement
          - schedule meals
          - improved outcomes
          - meet patients where they are

        </Col>
      </Container>
    </Section>
  </Layout>
)

export default Page