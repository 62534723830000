import Img from 'gatsby-image'
import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

export default ({
  path,
  ...rest
} = {}) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(edge => {
        return edge.node.relativePath.includes(path)
      })
      if (image)
        return <Img fluid={image.node.childImageSharp.fluid} {...rest} />
    }}
  />
)
