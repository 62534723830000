import React from 'react'

import { H1, H2, P, FinePrint } from '../base/Typography'
import Image from '../base/Image'

export const PostPreview = ({
  image,
  title,
  body,
  tags,
  date,
  url,
  children,
}) => (
  <div style={{ flex: 1, paddingBottom: 50, marginBottom: 50 }}>
    {image && (
      <Image
        path={image}
        style={{
          width: '100%',
          height: 250,
          borderRadius: 12,
          marginBottom: 30,
          boxShadow: 'rgba(0, 0, 0, .11) 0px 4px 120px -20px'
        }}
      />
    )}

    {title && <H2 style={{margin: 10}}>{title}</H2>}
    {date && <FinePrint>{date}</FinePrint>}
    {body && <P style={{margin: 10, fontSize: '1rem'}}>{body}</P>}
    {children}
  </div>
)



export const PostPreview2 = ({
  image,
  title,
  body,
  tags,
  date,
  url,
  children,
}) => (
  <div style={{ flex: 1, marginBottom: 50, display: 'flex' }}>
    <div style={{flex: 2}}>
    {image && (
      <Image
        path={image}
        style={{
          width: '100%',
          height: 300,
          borderRadius: 12,
          marginBottom: 30,
          boxShadow: 'rgba(0, 0, 0, .11) 0px 4px 120px -20px'
        }}
      />
    )}
    </div>
    <div style={{flex: 3, padding: 30}}>
    {title && <H2 style={{margin: 10}}>{title}</H2>}
    {date && <FinePrint>{date}</FinePrint>}
    {body && <P style={{margin: 10, fontSize: '1rem'}}>{body}</P>}
    {children}
    </div>
  </div>
)